import { environment } from 'src/environments/environment';
import { Component, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { VersionCheckService } from './shared/services/version-check.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private isAlive = true;

  constructor(
    private router: Router,
    private versionCheckService : VersionCheckService
  ) {
    this.versionCheckService.initVersionCheck('/version.json');

    this.router.events.pipe(takeWhile(() => this.isAlive)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/' && environment.project === "bella") {
          this.router.navigate(['/cadastro/step0']);
        }
      }
    });

    document.querySelector('html').style.setProperty('--primary-color', environment.primaryColor);
    document.querySelector('html').style.setProperty('--primary-color-hover', environment.primaryColorHover);
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
