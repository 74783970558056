// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  project: "meupetshop",
  primaryColor: "#793971",
  primaryColorHover: "#834c7c",
  projectName: "Meu PetShop",
  urlPortal : 'https://portal-dev.meupetshop.online/',
  urlEmpresa : 'https://parceiros-dev.meupetshop.online/',
  urlFunctions: 'https://us-central1-meu-petshop-dev.cloudfunctions.net',
  mapsKey: 'AIzaSyDraVsJldnq3ZcaUTKjeraKh25DFI4Aa0c',
  geocodeKey: 'AIzaSyDraVsJldnq3ZcaUTKjeraKh25DFI4Aa0c',

  firebaseConfig: {
    apiKey: "AIzaSyDraVsJldnq3ZcaUTKjeraKh25DFI4Aa0c",
    authDomain: "meu-petshop-dev.firebaseapp.com",
    projectId: "meu-petshop-dev",
    storageBucket: "meu-petshop-dev.appspot.com",
    messagingSenderId: "687937448080",
    appId: "1:687937448080:web:d29c534215d8d5a5d05015",
    measurementId: "G-B65MVHSB45"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
