import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentVersion = false;

  constructor(private http: HttpClient) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 5) {
    this.checkVersion(url);
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {

          if (this.currentVersion === false) {
            this.currentVersion = response.version;
          }

          const hash = response.version;
          const hashChanged = this.hasHashChanged(this.currentVersion, hash);

          console.log("===============================================");
          console.log("VERSÃO ATUAL DO PORTAL: ", this.currentVersion);
          console.log("===============================================");

          // If new version, do something
          if (hashChanged) {
            console.log("\n===============================================");
            console.log("NOVA VERSÃO DO PORTAL ENCONTRADA: ", hash);
            console.log("ATUALIZANDO O PORTAL EM 3,2,1,...");
            console.log("===============================================");
            location.reload();
          };

          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentVersion = hash;
        },
        (err) => {
          console.log("==================================================");
          console.log("NÃO FOI POSSÍVEL BUSCAR A VERSÃO ATUAL DO PORTAL.");
          console.log("==================================================");
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentVersion
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentVersion, newHash) {
    if (!currentVersion) {
      return false;
    }

    return currentVersion !== newHash;
  }
}
