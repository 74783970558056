import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {

    if (String(error.message).includes('ChunkLoadError')) {
      location.reload();
    } else {
      console.error(error);
    }
  }
}
